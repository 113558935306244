import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['content']

  static values = {
    hideWhenFieldEquals: String,
    showWhenFieldEquals: String,
  }

  handleStateChange(event) {
    let name, value

    if (event.detail) {
      ;({ name, value } = event.detail)
    } else {
      const target = event.target
      name = target.name
      value = target.value
    }

    if (!name || !value) return

    this.evaluateVisibility(name, value)
  }

  evaluateVisibility() {
    const shouldHide = this.evaluateRules(this.hideWhenFieldEqualsValue)
    const shouldShow = this.evaluateRules(this.showWhenFieldEqualsValue)

    this.contentTargets.forEach((target) => {
      if (shouldHide) {
        target.classList.add('hidden')
      } else if (shouldShow) {
        target.classList.remove('hidden')
      } else if (this.hideIfSelectedValue) {
        target.classList.remove('hidden')
      } else if (this.showIfSelectedValue) {
        target.classList.add('hidden')
      }
    })
  }

  evaluateRules(rulesString) {
    if (!rulesString) return false

    return rulesString.split(';').some((rule) => {
      if (!rule.trim()) return false

      const [name, expectedValue] = rule.split(':').map((part) => part.trim())
      if (!name || !expectedValue) return false

      // radio/checkox
      let input = this.element.querySelector(`input[name="${name}"]:checked`)
      if (input) {
        return input.value === expectedValue
      }

      // select
      input = this.element.querySelector(`select[name="${name}"]`)
      if (input) {
        return input.value === expectedValue
      }

      // other input?
      input = this.element.querySelector(`input[name="${name}"]`)
      if (input) {
        return input.value === expectedValue
      }

      return false
    })
  }
}
