/* global fetch, setTimeout */
import ApplicationController from './application_controller'
import { useResize as enableResize } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['overlay', 'iframe']
  static values = {
    retry: { type: Boolean, default: true },
    retryLimit: { type: Number, default: 4 },
    url: String,
    viewport: { type: String, default: 'desktop' },
    cacheOption: String,
  }

  viewports = {
    desktop: { width: 1200, height: 900 },
    fulldesktop: { width: 1200, height: '150vh' },
    mobile: { width: 390, height: 728 },
  }

  connect() {
    this.retryCount = 0

    enableResize(this)
    this.viewport = this.viewports[`${this.viewportValue}`]
    this.iframeTarget.style.width = `${this.viewport.width}px`
    this.iframeTarget.style.height = `${this.viewport.height}px`
    this.scaleIframe()

    this.checkUrl()
  }

  //** Checks if preview URL is successfully rendering and either loads the preview or waits and tries again */
  async checkUrl() {
    try {
      const fetchOptions = {
        headers: {
          Accept: 'text/html',
        },
      }

      if (this.cacheOptionValue) {
        fetchOptions.cache = this.cacheOptionValue
      }

      const response = await fetch(this.urlValue, fetchOptions)

      if (response.ok) {
        this.loadPreview()
      } else if (this.retryValue && this._canTryAgain) {
        this._waitAndCheckAgain()
      }
    } catch {
      if (this.retryValue && this._canTryAgain) {
        this._waitAndCheckAgain()
      }
    }
  }

  loadPreview() {
    this.iframeTarget.dataset.action = 'load->iframe-preview#loaded'
    this.iframeTarget.src = this.urlValue
  }

  loaded() {
    this.overlayTarget.remove()
  }

  resize() {
    this.scaleIframe()
  }

  scaleIframe() {
    this.element.style.height = `${this.element.offsetWidth * (this.viewport.height / this.viewport.width)}px`
    this.iframeTarget.style.transform = `scale(${this.scaleRatio})`
  }

  /**
   * Wait backoffDelay num of milliseconds and check URL again
   *
   * @private
   * @returns {void}
   */
  _waitAndCheckAgain() {
    this.retryCount++
    setTimeout(() => {
      this.checkUrl()
    }, this._backoffDelay)
  }

  /**
   * Exponential backoff based on retry count in milliseconds.
   *
   * @private
   * @returns {number} - number of milliseconds to wait
   */
  get _backoffDelay() {
    return Math.pow(2, this.retryCount) * 500
  }

  /**
   * @private
   * @returns {boolean} - wether or not to try again
   */
  get _canTryAgain() {
    return this.retryCount < this.retryLimitValue
  }

  get scaleRatio() {
    return this.element.offsetWidth / this.viewport['width']
  }
}
