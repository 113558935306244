import { IntegryJS } from '@integry/sdk'
import { destroy, post } from '@rails/request.js'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    appKey: String,
    userId: String,
    teamId: Number,
    workspaceId: Number,
    apiKey: String,
    subdomain: String,
    hash: String,
    bundleId: String,
    baseDomain: String,
    installationPath: String,
    destroyPath: String,
    saveButtonText: String,
    cfClassicImportEnabled: Boolean,
    googleSheetsImportEnabled: Boolean,
  }

  async loadIntegry() {
    const appKey = this.appKeyValue
    const userId = this.userIdValue
    const tag = this.bundleIdValue
    const team_id = this.teamIdValue
    const workspace_id = this.workspaceIdValue
    const subdomain = this.subdomainValue
    const hash = this.hashValue
    const api_key = this.apiKeyValue
    const base_domain = this.baseDomainValue

    this.sdkInstance = new IntegryJS({
      appKey,
      hash,
      userId,
      options: {
        tags: [tag],
      },
      xIntegryConfig: {
        appAuth: {
          apiKey: api_key,
          extras: {
            cf2_team_id: team_id,
            cf2_workspace_id: workspace_id,
            // KEEP THIS: used in constructing api urls in Integry steps
            cf2_subdomain: subdomain,
            // KEEP THIS: used in constructing api urls in Integry steps
            cf2_url: base_domain,
          },
        },
      },
    })
  }

  async deleteInstallationByConnectedAccountID(connected_account_id) {
    await destroy(this.destroyPathValue, {
      body: { integrations_integry_installation: { integry_connected_account_id: connected_account_id } },
      responseKind: 'text/html',
    })
  }

  async saveConnectedAccount(data) {
    const identity = data.connected_accounts.find((account) => account.id === data.connected_account_id)?.display_name
    const params = {
      integry_connected_account_id: data.connected_account_id,
      integry_user_identity: identity,
      name: identity,
      active: true,
    }

    const response = await post(this.installationPathValue, {
      body: {
        integrations_integry_installation: { ...params },
        integry_app: {
          id: data.id,
          logo_url: data.icon_url,
          name: data.name,
        },
      },
      responseKind: 'json',
    })

    if (response.ok) {
      globalThis.window.location.reload()
    }
  }
}
