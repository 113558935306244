/* global AbortController, window, setTimeout */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['otpField', 'submitButton', 'otpForm', 'hiddenInput', 'recoveryCodeInput', 'otpFieldContainer']

  static values = {
    autoSubmit: { type: Boolean, default: true },
    emulateSubmit: Boolean,
  }

  connect() {
    this.abortController = new AbortController()
    this.setupEventListeners()
  }

  disconnect() {
    this.abortController.abort()
  }

  setupEventListeners() {
    const target = this.hasOtpFieldContainerTarget ? this.otpFieldContainerTarget : this.otpFormTarget

    target.addEventListener('paste', this.handlePaste.bind(this), {
      signal: this.abortController.signal,
    })

    target.addEventListener('input', this.handleInput.bind(this), {
      signal: this.abortController.signal,
    })

    target.addEventListener('keydown', this.handleBackspace.bind(this), {
      signal: this.abortController.signal,
    })
  }

  handlePaste(event) {
    const pastedValue = (event.clipboardData || window.clipboardData).getData('text').trim()

    if (pastedValue.length !== this.otpLength) return

    this.fillOtpFields(pastedValue)

    this.otpFieldTargets[this.otpLength - 1].focus()
  }

  handleInput(event) {
    const currentInput = event.target
    const index = this.otpFieldTargets.indexOf(currentInput)

    if (currentInput.value.length === 1 && index < this.otpLength - 1) {
      this.otpFieldTargets[index + 1].focus()
    }

    setTimeout(() => this.syncHiddenInput())
  }

  handleBackspace(event) {
    if (event.key !== 'Backspace') return

    const currentInput = event.target
    const index = this.otpFieldTargets.indexOf(currentInput)

    if (index > 0 && !currentInput.value) {
      this.otpFieldTargets[index - 1].focus()
    }

    setTimeout(() => this.syncHiddenInput())
  }

  syncHiddenInput() {
    if (this.otpFieldTargets.some((input) => !input.value)) {
      return
    }

    const otpValue = this.otpFieldTargets.map((input) => input.value).join('')
    this.hiddenInputTarget.value = otpValue

    if (this.autoSubmitValue) {
      this.submitForm()
    }
  }

  fillOtpFields(value) {
    this.otpFieldTargets.forEach((input, index) => {
      input.value = value[index] || ''
    })
  }

  handleRecoveryCodeInput() {
    this.hiddenInputTarget.value = this.recoveryCodeInputTarget.value
  }

  submitForm() {
    if (this.emulateSubmitValue && this.hasSubmitButtonTarget) {
      this.otpFormTarget.requestSubmit(this.submitButtonTarget)
    } else {
      this.otpFormTarget.requestSubmit()
    }
  }

  get otpLength() {
    return this.otpFieldTargets.length
  }
}
