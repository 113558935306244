import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    if (typeof window.sfStepsForm === 'undefined') {
      window.sfStepsForm = function (currentStep) {
        return {
          currentStep: 0,
          totalSteps: 4,
          funnelType: 'webinar',

          init() {
            if (!isNaN(currentStep) && currentStep >= 0 && currentStep < this.totalSteps) {
              this.currentStep = currentStep
            }
          },
        }
      }
    }

    this.element.querySelectorAll('.group button').forEach((button) => {
      button.addEventListener('click', function () {
        let radio = this.parentElement.querySelector('input[type="radio"]')
        radio.checked = true
      })
    })

    this.element.querySelectorAll('[x-ref]').forEach((element) => {
      $(element).on('select2:select select2:unselect', (event) => {
        const selectedValue = $(event.target).val()
        const refName = $(event.target).attr('x-ref')

        // Update all other selects with the same x-ref
        $(`[x-ref=${refName}]`)
          .not(event.target)
          .each(function () {
            $(this).val(selectedValue).trigger('change.select2')
          })

        event.target.dispatchEvent(new Event('cf:change', { bubbles: true }))
      })
    })
  }
}
