/* global document, requestAnimationFrame */

/* NOTE: This controller has a dependency on the Shoelace Tooltip component. */
import ApplicationController from './application_controller'

const STRATEGIES = {
  multiline: 'multiline',
  singleline: 'singleline',
  both: 'both',
}

export default class extends ApplicationController {
  static targets = ['text']
  static values = {
    strategy: { type: String, default: STRATEGIES.multiline },
    placement: { type: String, default: 'top' },
    message: String, // override the target's textContent if desired
    showDelay: String,
    hideDelay: String,
    maxWidth: String,
    classes: String,
  }

  strategyMethods = {}

  initialize() {
    this.strategyMethods = {
      [STRATEGIES.multiline]: () => this.checkForMultilineTruncation(),
      [STRATEGIES.singleline]: () => this.checkForSingleLineTruncation(),
      [STRATEGIES.both]: () => this.checkForMultilineTruncation() || this.checkForSingleLineTruncation(),
    }
  }

  connect() {
    requestAnimationFrame(() => {
      this.checkIfTruncated()
    })
  }

  checkIfTruncated() {
    const strategyCheck = this.strategyMethods[this.strategyValue]

    if (!strategyCheck || !strategyCheck()) return

    this.applyTooltip()
  }

  checkForMultilineTruncation() {
    return this.textTarget.scrollHeight > this.textTarget.clientHeight
  }

  checkForSingleLineTruncation() {
    const textWidth = this.textTarget.scrollWidth
    const containerWidth = this.textTarget.getBoundingClientRect().width
    return textWidth >= containerWidth
  }

  applyTooltip() {
    const fragment = document.createDocumentFragment()
    const tooltip = document.createElement('sl-tooltip')

    tooltip.content = this.hasMessageValue ? this.messageValue : this.textTarget.textContent
    tooltip.setAttribute('placement', this.placementValue)

    const styles = this.#buildStyleOptions()
    if (styles.length) {
      tooltip.setAttribute('style', styles)
    }

    if (this.hasClassesValue) {
      tooltip.setAttribute('class', this.classesValue)
    }

    fragment.appendChild(tooltip)
    this.textTarget.parentNode.insertBefore(fragment, this.textTarget)
    tooltip.appendChild(this.textTarget)
  }

  #buildStyleOptions() {
    const options = [
      this.hasShowDelayValue && `--show-delay: ${this.showDelayValue}`,
      this.hasHideDelayValue && `--hide-delay: ${this.hideDelayValue}`,
      this.hasMaxWidthValue && `--max-width: ${this.maxWidthValue}`,
    ]
      .filter(Boolean)
      .join('; ')

    return options
  }
}
