/* global document */
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'select', 'error']

  connect() {
    this.#setupFormValidation()
  }

  toggle() {
    const { value: format } = this.selectTarget
    const input = this.inputTarget

    this.#clearErrorMessage()

    Object.assign(input, {
      name: `discount[${format === 'fixed' ? 'amount' : 'percent'}]`,
      id: `discount_${format === 'fixed' ? 'amount' : 'percent'}`,
      value: '',
    })
  }

  #setupFormValidation() {
    const form = this.inputTarget.closest('form')
    if (!form) return

    form.addEventListener('submit', (e) => {
      if (!this.inputTarget.value.trim()) {
        e.preventDefault()
        this.#showErrorMessage(`${this.selectTarget.value === 'fixed' ? 'Amount' : 'Percent'} is required`)
      }
    })
  }

  #showErrorMessage(message) {
    this.errorTarget.textContent = message
    this.errorTarget.classList.remove('hidden')
  }

  #clearErrorMessage() {
    this.errorTarget.textContent = ''
    this.errorTarget.classList.add('hidden')
  }
}
