import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['preview']

  updatePreview(event) {
    this.previewTarget.src = event.detail.preview
  }

  resetPreview() {
    this.previewTarget.src = ''
  }
}
