import ApplicationController from '../application_controller'
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    publicKey: String,
    clientSecret: String,
    stripeAccountId: String,
    returnUrl: String,
    billingDetails: Object,
    totalAmount: Number,
    mode: String,
    currency: String,
    confirmationTokenPath: String,
    systemErrorMessage: String,
    allowCreditCardFieldEntry: { type: Boolean, default: true }
  }

  static targets = ['outOfBandElement', 'cash', 'check', 'submitPaymentButton', 'selectedMethod', 'form']

  connect() {
    this.initializeOutofBandElements()
    if (this.allowCreditCardFieldEntryValue) {
      this.initializePaymentMethodGroups()
      this.initializeStripe()
      // Stripe is open by default.
      this.selectedMethod = 'stripe'
    } else {
      this.selectedMethod = 'existing-payment-method'
    }
  }

  async handleSubmit(e) {
    // Ensure we only submit on explict form submission, not on enter key.
    if (e.offsetX === 0) {
      e.preventDefault()
      return
    }

    this.selectedMethodTarget.value = this.selectedMethod

    if (this.stripeSelected()) {
      this.submitStripe(e)
    } else if (this.outOfBandSelected()) {
      this.submitOutOfBand(e)
    }
  }

  async submitOutOfBand(e) {
    // We're actually just submitting the form here, so we don't need to do anything.
    this.setLoading(true)
  }

  async submitStripe(e) {
    e.preventDefault()

    this.setLoading(true)
    this.hideErrorMessage()
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId)
    }

    const { error: submitError } = await this.elements.submit()
    if (submitError) {
      // This error should be shown by the payment element.
      this.setLoading(false)
      return
    }

    const { error, confirmationToken } = await this.stripe.createConfirmationToken({
      elements: this.elements,
      params: {
        payment_method_data: {
          billing_details: this.billingDetailsValue,
          allow_redisplay: 'always',
        },
      },
    })

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      this.showErrorMessage(error.message)
      this.setLoading(false)
      return
    }

    // We have a confirmationToken, we will use it to create the payment.
    if (confirmationToken) {
      const formData = new FormData(this.formTarget)

      this.appendNestedObjectToFormData(formData, 'confirmation_token', confirmationToken)

      const request = new FetchRequest('PUT', this.confirmationTokenPathValue, {
        responseKind: 'json',
        body: formData,
      })
      request
        .perform()
        .then((response) => response.json)
        .then((response) => {
          if (response.status === 'succeeded') {
            // Redirect to success page
            this.redirectToFinalUrl(response)
          } else if (response.status === 'requires_action') {
            // Redirect to 3D Secure page /  Show 3DS secure 2 modal.
            this.stripe
              .handleNextAction({
                clientSecret: response.client_secret,
              })
              .then(({ error, paymentIntent }) => {
                if (error) {
                  this.showErrorMessage(error.message)
                  this.setLoading(false)
                } else {
                  // Actions handled, redirect to success page
                  this.redirectToFinalUrl(response)
                }
              })
          } else {
            // Show an error message
            if (response.error) {
              this.showErrorMessage(response.error.message)
            }
            this.setLoading(false)
          }
        })
        .catch((error) => {
          this.showErrorMessage(this.systemErrorMessageValue)
          this.setLoading(false)
        })
    }
  }

  redirectToFinalUrl(response) {
    const url = new URL(this.returnUrlValue)
    url.searchParams.append(response.object, response.id)
    Turbo.visit(url.toString())
  }

  setLoading(isLoading) {
    this.isLoading = isLoading
    if (isLoading) {
      // Disable the button and show a spinner
      this.submitPaymentButtonTarget.querySelector('#spinner').classList.remove('hidden')
      this.submitPaymentButtonTarget.querySelector('#button-text').classList.add('hidden')
      setTimeout(() => this.submitPaymentButtonTarget.setAttribute('disabled', ''), 1)
    } else {
      this.setSubmitButtonState()
      this.submitPaymentButtonTarget.querySelector('#spinner').classList.add('hidden')
      this.submitPaymentButtonTarget.querySelector('#button-text').classList.remove('hidden')
    }
  }

  showErrorMessage(messageText) {
    this.messageContainer.classList.remove('hidden')
    this.messageContainer.textContent = messageText

    this.hideTimeoutId = setTimeout(() => {
      this.hideErrorMessage()
    }, 5000)
  }

  hideErrorMessage() {
    this.messageContainer.classList.add('hidden')
    this.messageContainer.textContent = ''
  }

  hasBillingDetails() {
    return this.billingDetailsValue.address?.country?.trim() && this.billingDetailsValue.address?.postal_code?.trim()
  }

  initializePaymentMethodGroups() {
    const container = document.querySelector('.payment-method-details-group')

    // Close all other details when one is shown
    container.addEventListener('sl-show', (event) => {
      // Don't allow the user to open other details while we're submitting the form.
      if (this.isLoading) {
        event.preventDefault()
        return
      }

      ;[...container.querySelectorAll('sl-details')].map((details) => {
        details.open = event.target === details
        if (details.open) {
          this.selectedMethod = details.dataset['paymentMethodType']
        }
        this.setSubmitButtonState()
      })
    })
  }

  initializeOutofBandElements() {
    this.outOfBandElementTargets.forEach((item) => {
      item.addEventListener('change', (e) => {
        this.setSubmitButtonState()
        const outOfBandElementValue = e.target.value

        if (outOfBandElementValue == 'cash') {
          this.cashTarget.classList.remove('hidden')
          this.checkTarget.classList.add('hidden')
        }
        if (outOfBandElementValue == 'check') {
          this.checkTarget.classList.remove('hidden')
          this.cashTarget.classList.add('hidden')
        }
        if (outOfBandElementValue == 'miscellaneous') {
          this.cashTarget.classList.add('hidden')
          this.checkTarget.classList.add('hidden')
        }
      })
    })
  }

  initializeStripe() {
    /* global Stripe */
    this.stripe = Stripe(this.publicKeyValue, { stripeAccount: this.stripeAccountIdValue, betas: ['disable_link_passthrough_beta_1'] })

    const options = {
      currency: this.currencyValue,
      payment_method_types: ['card'],
      customerSessionClientSecret: this.clientSecretValue,
      setup_future_usage: 'off_session',
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      },
    }

    if (this.totalAmountValue > 0) {
      options.amount = this.totalAmountValue
      options.mode = this.modeValue
    } else {
      options.mode = 'setup'
    }

    const paymentElementOptions = {
      layout: 'tabs',
      fields: {
        billingDetails: this.hasBillingDetails() ? 'never' : 'auto',
      },
    }

    this.elements = this.stripe.elements(options)
    this.paymentElement = this.elements.create('payment', paymentElementOptions)
    this.paymentElement.mount('#payment-element')
    this.messageContainer = document.querySelector('#payment-message')
  }

  stripeSelected() {
    return this.selectedMethod === 'stripe'
  }

  outOfBandSelected() {
    return this.selectedMethod === 'out-of-band'
  }

  setSubmitButtonState() {
    if (this.isLoading) {
      return
    }

    this.submitPaymentButtonTarget.setAttribute('disabled', '')
    if (this.stripeSelected()) {
      this.submitPaymentButtonTarget.removeAttribute('disabled')
    } else if (this.outOfBandSelected() && this.outOfBandElementTargets.find((e) => e.checked)) {
      this.submitPaymentButtonTarget.removeAttribute('disabled')
    }
  }

  appendNestedObjectToFormData(formData, key, value) {
    if (typeof value === 'object' && value !== null) {
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        this.appendNestedObjectToFormData(formData, `${key}[${nestedKey}]`, nestedValue)
      }
    } else {
      formData.append(key, value !== null ? value : '')
    }
  }
}
