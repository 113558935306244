import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['timeZoneInput']

  connect() {
    // Get browser timezone
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Convert browser timezone format to Rails format
    const railsTimezone = this.convertToRailsTimezone(browserTimezone)

    // Set the hidden field value
    this.timeZoneInputTarget.value = railsTimezone
  }

  convertToRailsTimezone(browserTz) {
    // Mapping of common browser timezones to Rails timezones
    const tzMapping = {
      'America/New_York': 'Eastern Time (US & Canada)',
      'America/Chicago': 'Central Time (US & Canada)',
      'America/Denver': 'Mountain Time (US & Canada)',
      'America/Los_Angeles': 'Pacific Time (US & Canada)',
      'America/Phoenix': 'Arizona',
      'America/Anchorage': 'Alaska',
      'Pacific/Honolulu': 'Hawaii',
      'America/Toronto': 'Eastern Time (US & Canada)',
      'Europe/London': 'London',
      'Europe/Paris': 'Paris',
      // Add more mappings as needed
    }

    return tzMapping[browserTz] || 'Eastern Time (US & Canada)' // Default to Eastern Time if no match
  }
}
