/* global window, document, URL, setTimeout, URLSearchParams */
// to be used with sl-tab-group
import ApplicationController from '../application_controller'

// This controller is used to handle the tab group component
// @see https://shoelace.style/components/tab-group
// @param keepScrollPosition [Boolean] - If true, the scroll position will be kept when changing tabs
// @usage <div data-controller="shoelace--tab-group" data-shoelace--tab-group-keep-scroll-position-value="true">

export default class extends ApplicationController {
  static values = {
    keepScrollPosition: { type: Boolean, default: false },
  }

  connect() {
    this.currentScrollYPosition = 0
    this.navigateToTab = this.navigateToTab.bind(this)
    this.handleTabShow = this.handleTabShow.bind(this)
    this.handlePopState = this.handlePopState.bind(this)
    this.preventVisit = false

    this.initialHash = window.location.hash

    this.navigateToTab()

    document.addEventListener('turbo:render', this.navigateToTab)
    this.element.addEventListener('sl-tab-show', this.handleTabShow)
    window.addEventListener('popstate', this.handlePopState)
  }

  disconnect() {
    document.removeEventListener('turbo:render', this.navigateToTab)
    this.element.removeEventListener('sl-tab-show', this.handleTabShow)
    window.removeEventListener('popstate', this.handlePopState)
  }

  handleTabShow(event) {
    // Don't create new history entry if we're handling a popstate event
    if (!this.handlingPopState && !this.preventVisit && event.detail.name !== this.initialHash.slice(1)) {
      const newUrl = new URL(window.location)
      newUrl.hash = event.detail.name
      Turbo.navigator.history.push(newUrl)
    }

    this.initialHash = ''

    if (this.keepScrollPositionValue) {
      this.handleTabChange()
    }
  }

  handlePopState() {
    const hash = window.location.hash.slice(1)

    if (hash) {
      // show the tab specified in the hash
      this.handlingPopState = true
      this.preventVisit = true
      this.element.show(hash)
      this.preventVisit = false
      this.handlingPopState = false
    } else {
      // show first tab when no hash is present
      const firstTab = this.element.querySelector('sl-tab')
      if (!firstTab) return

      this.handlingPopState = true
      this.preventVisit = true
      this.element.show(firstTab.panel)
      this.preventVisit = false
      this.handlingPopState = false
    }
  }

  navigateToTab() {
    let hash = window.location.hash.toString()
    if (hash) {
      setTimeout(() => {
        this.preventVisit = true
        this.element.show(hash.slice(1))
        this.preventVisit = false
      })
    } else {
      /*
       * Turbo doesn't currently support hashes on redirects (see https://github.com/hotwired/turbo/issues/825)
       * so we've created a workaround. Pass `redirect_anchor` as a query param, and the component will convert
       * it to a hash and delete the query param. e.g. `some_url?redirect_anchor=store_upsells`
       */
      const params = new URLSearchParams(window.location.search)
      const redirectedHashParam = params.get('redirect_anchor')
      if (redirectedHashParam) {
        params.delete('redirect_anchor')
        let newParams = params.toString()
        window.history.replaceState(
          null,
          '',
          [window.location.pathname, newParams ? `?${newParams}` : '', '#', redirectedHashParam].join('')
        )
        this.preventVisit = true
        this.element.show(redirectedHashParam)
        this.preventVisit = false
      }
    }
  }

  openTab(event) {
    event.preventDefault()
    const url = new URL(event.target.href)
    const newTab = url.hash.slice(1)
    window.history.replaceState(null, '', url.toString())

    this.preventVisit = true
    this.element.show(newTab)
    this.preventVisit = false
  }

  handleTabChange() {
    this.currentScrollYPosition = window.scrollY
  }

  /**
   * Programmatically reveal a sl-tab-panel via action params.
   * @see Shoelace sl-tab-group show() method
   * @see https://shoelace.style/components/tab-group?id=methods
   *
   * @param {Event} event
   * @param {string} event.params.showPanel - the name attribute of the sl-tab-panel to show
   * @example <button type='button' data-action="click->shoelace--tab-group#show"
   *   data-shoelace--tab-group-show-panel-param="mypanelname">
   * @returns {void}
   */
  show(event) {
    const { showPanel } = event.params
    this.element.show(showPanel)
  }
}
