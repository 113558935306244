import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'select']

  /**
   * Clears the input value and toggles the input name based on the selected format
   *
   */
  toggle() {
    this.#clearInputValue()
    this.#updateInputName()
  }

  // Private

  /**
   * Clears the input value
   */
  #clearInputValue() {
    this.inputTarget.value = ''
  }

  /**
   * Updates input name when format selection changes
   */
  #updateInputName() {
    const input = this.inputTarget
    const selectedFormat = this.selectTarget.value

    // Determine the new name based on format with support for multiple levels
    const currentName = input.name
    const newName = this.#replaceCommissionType(currentName, selectedFormat)

    // Update the input name if it has changed
    if (newName !== currentName) {
      input.name = newName
    }
  }

  /**
   * Updates the commission type in the input name based on the selected format.
   *
   * @param {string} name - The input name containing the current commission type.
   * @param {string} selectedFormat - The desired format ('percent' or 'fixed').
   * @returns {string} The input name with the updated commission type.
   * @private
   */

  #replaceCommissionType(name, selectedFormat) {
    const formatMapping = {
      percent: 'commission_percent',
      fixed: 'commission_fixed',
    }

    // Find the current commission type in the name
    const currentType = Object.values(formatMapping).find((type) => name.includes(type))

    if (!currentType) return name

    // Determine the target type based on selected format
    const targetType = formatMapping[selectedFormat]

    // If the current type matches the desired format, no replacement needed
    if (currentType.startsWith(targetType)) return name

    // Replace the current type with the target type
    return name.replace(currentType, currentType.replace(/(percent|fixed)/, selectedFormat))
  }
}
