import { useDispatch } from 'stimulus-use'
import IntegryController from './integry_controller'
import { IntegryJS } from '@integry/sdk'

export default class extends IntegryController {
  static values = {
    providerId: String,
    connectedAccountId: String,
    indexPath: String,
  }

  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
    this.loadIntegry()
    this.attachEvents()
    this.showApps()
  }

  attachEvents() {
    this.sdkInstance.eventEmitter.on('app-connected', (data) => {
      this.saveConnectedAccount(data)
    })

    this.sdkInstance.eventEmitter.on('app-disconnected', (data) => {
      this.deleteInstallationByConnectedAccountID(data.connected_account_id)
    })
  }

  showApps() {
    this.sdkInstance.showApps(IntegryJS.RenderModes.INLINE, 'integry-auth-container')
  }
}
