import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['output']
  static values = { zeroText: String, oneText: String, pluralText: String }

  update(event) {
    // Controller Dependency: use of <select> element
    // The selectedOptions property comes from the HTML <select> element.
    // It is a standard DOM property that provides a collection of the <option>
    // elements that are currently selected within a <select> element.
    const selectedItems = event.target.selectedOptions
    const itemCount = selectedItems.length

    let buttonText
    switch (itemCount) {
      case 0:
        buttonText = this.zeroTextValue
        break
      case 1:
        buttonText = this.oneTextValue
        break
      default:
        buttonText = this.pluralTextValue?.replace('{count}', itemCount)
        break
    }

    this.outputTarget.textContent = buttonText
  }
}
