/* global document */
export function toast(type, message, toasterFrameId = 'toaster', duration = 0) {
  let toastContent = document
    .querySelector(`template[data-id='toast-template'][data-type=${type}]`)
    .content.cloneNode(true)

  if (duration > 0) {
    toastContent.querySelector('sl-alert').setAttribute('duration', duration)
  }

  toastContent.getElementById('alert-message').innerText = message
  document.getElementById(toasterFrameId).appendChild(toastContent)
}
