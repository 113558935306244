import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['content', 'icon']

  connect() {
    this.updateIconRotation()
  }

  toggle() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.toggle('hidden')
      this.element.dataset.collapsed = this.isCollapsed ? 'false' : 'true'
      this.updateIconRotation()
    }
  }

  expand() {
    if (this.hasContentTarget && this.isCollapsed) {
      this.contentTarget.classList.remove('hidden')
      this.element.dataset.collapsed = 'false'
      this.updateIconRotation()
    }
  }

  collapse() {
    if (this.hasContentTarget && !this.isCollapsed) {
      this.contentTarget.classList.add('hidden')
      this.element.dataset.collapsed = 'true'
      this.updateIconRotation()
    }
  }

  get isCollapsed() {
    return this.contentTarget.classList.contains('hidden')
  }

  updateIconRotation() {
    if (this.hasIconTarget) {
      this.iconTarget.style.transform = this.isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)'
    }
  }
}
