/* global FileReader */
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['fileField', 'nullState', 'selectedState', 'hiddenField', 'removeField', 'dragArea']

  handleSelected(event) {
    const selectedFile = event.target.files[0]

    if (selectedFile) {
      this.fileFieldTarget.files = event.target.files
    }
    this.generateThumbnail(selectedFile)
  }

  handleDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    this.dragAreaTarget.classList.add(this.dragOverClass)
  }

  handleDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.dragAreaTarget.contains(event.relatedTarget)) {
      this.dragAreaTarget.classList.remove(this.dragOverClass)
    }
  }

  handleDrop(event) {
    event.preventDefault()
    event.stopPropagation()

    // Transfer the FileList directly from the dataTransfer
    const fakeEvent = { target: { files: event.dataTransfer.files } }
    this.handleSelected(fakeEvent)
    this.dragAreaTarget.classList.remove(this.dragOverClass)
  }

  reset() {
    this.dispatch('image-reset', { bubbles: true })
    this.fileFieldTarget.value = ''
    this.hiddenFieldTarget.value = ''
    this.removeFieldTarget.value = 'true'
    this.selectedStateTarget.classList.add('hidden')
    this.nullStateTarget.classList.remove('hidden')
  }

  generateThumbnail(file) {
    const reader = new FileReader()
    reader.onload = (event) => {
      this.insertThumbnail(event.target.result)
    }
    reader.readAsDataURL(file)
  }

  insertThumbnail(preview) {
    this.dispatch('image-selected', { detail: { preview }, bubbles: true })
    this.removeFieldTarget.value = 'false'
    this.selectedStateTarget.querySelector('img').src = preview
    this.selectedStateTarget.classList.remove('hidden')
    this.nullStateTarget.classList.add('hidden')
  }

  get dragOverClass() {
    return 'bg-coolGray-100'
  }
}
