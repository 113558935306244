/* global setTimeout */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    behavior: { type: String, default: 'smooth' },
    block: { type: String, default: 'center' },
    inline: { type: String, default: 'nearest' },
    selectorTarget: String,
    runOnConnect: { type: Boolean, default: true },
  }

  connect() {
    if (this.runOnConnectValue) {
      this.scrollToElement()
    }
  }

  scrollToElement() {
    setTimeout(() => {
      const target = this.hasSelectorTargetValue ? this.element.querySelector(this.selectorTargetValue) : this.element

      target.scrollIntoView({
        behavior: this.behaviorValue,
        block: this.blockValue,
        inline: this.inlineValue,
      })
    })
  }
}
