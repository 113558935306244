import ApplicationController from './application_controller'
import { patch } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['roleId', 'buttonText', 'errorMessage']
  static values = {
    url: String,
    pluralText: String,
    emptyText: String,
    bodyShape: String,
    membershipId: String
  }

  async toggle(event) {
    let roleIds = this.roleIdTargets
      .filter((roleCheckbox) => roleCheckbox.checked)
      .map((roleCheckbox) => roleCheckbox.value)

    let checkbox = event.target
    let checkboxChecked = checkbox.checked

    // Replace the checkbox with the loading spinner
    checkbox.previousElementSibling.classList.remove('hidden')
    checkbox.classList.add('hidden')
    let errorMessage = this.element.querySelector('.settings-error')
    if (errorMessage) {
      errorMessage.remove()
    }

    let body = { workspaces_collaborator: { membership_id: this.membershipIdValue, role_ids: roleIds } }
    if (this.bodyShapeValue == 'membership') {
      body = {
        membership: { role_ids: roleIds },
      }
    }

    const response = await patch(this.urlValue, {
      body,
      contentType: 'application/json',
      responseKind: 'json',
    })

    if (response.ok) {
      let text = ''
      if (roleIds.length === 1) {
        let checkboxId = this.roleIdTargets.find((checkbox) => checkbox.checked)?.id
        text = document.querySelector('label[for="' + checkboxId + '"] .name')?.innerHTML
      } else if (roleIds.length === 0 && this.emptyTextValue.length) {
        text = this.emptyTextValue
      } else {
        text = this.pluralTextValue
      }
      if (text)
        this.buttonTextTarget.innerHTML = text.replace(/{count}/, roleIds.length)
    } else {
      let error = await response.json
      let errorMessage = document.createElement('div')
      errorMessage.className = 'p-2 my-2 text-sm text-red-700 bg-red-100 rounded settings-error'
      errorMessage.innerHTML = error['base']
      this.errorMessageTarget.prepend(errorMessage)
      checkbox.checked = !checkboxChecked // Revert the checkbox to the original state
    }

    // Replace the loading spinner with the checkbox
    checkbox.previousElementSibling.classList.add('hidden')
    checkbox.classList.remove('hidden')
  }
}
