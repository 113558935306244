import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'passwordInputField',
    'passwordInputConfirmationField',
    'passwordMismatchWarning',
    'changePasswordSubmitButton',
  ]
  static classes = ['hide']

  checkMatchingPasswords() {
    const password = this.passwordInputFieldTarget.value
    const passwordConfirmation = this.passwordInputConfirmationFieldTarget.value

    if (password !== passwordConfirmation && password !== '' && passwordConfirmation !== '') {
      this.passwordMismatchWarningTarget.classList.remove(...this.hideClasses)
    } else {
      this.passwordMismatchWarningTarget.classList.add(...this.hideClasses)
    }
  }
}
