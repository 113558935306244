/* global FormData, setTimeout, clearTimeout, URLSearchParams */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['frame']
  static values = {
    url: String,
  }

  connect() {
    this.setupFormListener()
  }

  setupFormListener() {
    const form = this.element.closest('form')
    if (!form) return

    form.addEventListener('change', () => this.debouncedUpdate())
    form.addEventListener('input', () => this.debouncedUpdate())
  }

  debouncedUpdate() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.updatePreview(), 300)
  }

  updatePreview() {
    const form = this.element.closest('form')
    const formData = new FormData(form)
    const searchParams = new URLSearchParams()

    // Ensure we're sending the boostable_type first
    const boostableType = formData.get('conversion_booster[boostable_type]')
    if (boostableType) {
      searchParams.append('conversion_booster[boostable_type]', boostableType)
    }

    // Then append all other form data
    for (const [key, value] of formData.entries()) {
      if (key !== 'conversion_booster[boostable_type]') {
        searchParams.append(key, value)
      }
    }

    // Update iframe src with form data
    const previewUrl = `${this.urlValue}?${searchParams.toString()}`
    this.frameTarget.src = previewUrl
  }
}
