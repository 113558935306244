import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  ACTIVE_RADIO_CLASSES= ['border-blue', 'bg-blue', 'shadow-solid-inner', 'shadow-white']
  ACTIVE_CARD_CLASSES= ['border-blue', 'bg-blue-50']

  static targets = ['selectedPaymentMethod', 'selectedMethod']

  select(event) {
    this.#deselectAll()
    this.#selectedClicked(event)

    const clickedElement = event.currentTarget;
    const selectedPaymentMethodId = clickedElement.dataset.id;

    // Update the hidden field value
    this.selectedPaymentMethodTarget.value = selectedPaymentMethodId;
  }

  #selectedClicked(event) {
    const clickedElement = event.currentTarget;

    const parentCard = clickedElement.closest(".payment-method-card")
    parentCard.classList.add(...this.ACTIVE_CARD_CLASSES)

    const radio = Array.from(parentCard.querySelectorAll(".payment-method-radio"))
        .find(el => el !== clickedElement);

    radio.classList.add(...this.ACTIVE_RADIO_CLASSES);
  }
  #deselectAll() {
    const cards = document.querySelectorAll(".payment-method-card");
    const radio_elements = document.querySelectorAll(".payment-method-radio");

    cards.forEach((element) => {
      element.classList.remove(...this.ACTIVE_CARD_CLASSES);
    });

    radio_elements.forEach((element) => {
      element.classList.remove(...this.ACTIVE_RADIO_CLASSES);
    });
  }
}
