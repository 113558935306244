import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['validationRulesList']

  invalidRules = []

  connect() {
    // Ideally these rules would be passed in and driven by app config.
    // For now, we'll match the rules from the devise-security gem:
    // eslint-disable-next-line max-len
    // https://github.com/devise-security/devise-security/blob/main/lib/devise-security/validators/password_complexity_validator.rb#L28-L35
    this.rules = [
      {
        name: 'minLength',
        test: /^.{12,}$/,
        prompt: 'Must be at least 12 characters long',
      },
      {
        name: 'uppercase',
        test: /\p{Lu}/u,
        prompt: 'Must contain at least one uppercase letter',
      },
      {
        name: 'lowercase',
        test: /\p{Ll}/u,
        prompt: 'Must contain at least one lowercase letter',
      },
      {
        name: 'number',
        test: /\p{Nd}/u,
        prompt: 'Must contain at least one number',
      },
      {
        name: 'special',
        test: /\p{P}|\p{S}/u,
        prompt: 'Must contain at least one special character',
      },
    ]
  }

  validatePassword(event) {
    const value = event.target.value

    if (!value) {
      this.validationRulesListTarget.innerHTML = ''
      return
    }

    this.invalidRules = []

    this.rules.forEach((rule) => {
      const isValid = rule.test instanceof RegExp ? rule.test.test(value) : rule.test(value)

      if (!isValid) {
        this.invalidRules.push(rule)
      }
    })

    this.validationRulesListTarget.innerHTML = ''

    if (this.invalidRules.length > 0) {
      const rule = this.invalidRules[0]

      const errorMessage = `
        <li 
          class="group flex items-center gap-1 is-invalid" 
          data-rule-name="${rule.name}"
          role="status"
          aria-live="polite"
        >
          <i class="fa-solid fa-circle-xmark text-red"></i>
          <span class="text-label-sm text-red">${rule.prompt}</span>
        </li>
      `
      this.validationRulesListTarget.innerHTML = errorMessage
    }
  }
}
