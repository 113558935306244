/* global window entri */

import ApplicationController from './application_controller'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['validateOwnershipText', 'validateOwnershipLoader', 'checkConnectionText', 'checkConnectionLoader']
  static values = {
    config: Object,
    workspaceId: String,
  }

  wwwToggle() {
    let config = this.configValue
    if (typeof config !== 'undefined') {
      config['wwwRedirect'] = !config['wwwRedirect']
    }
  }

  showManual() {
    $('.manual_setup').show()
    $('#check-connection').show()
  }

  entriPurchase(e) {
    e.preventDefault()
    let config = this.configValue
    // entri is a cdn package included in the view
    entri.purchaseDomain(config)
  }

  showEntri(event) {
    let trackUserEventKey = event.params.trackUserEventKey

    if (trackUserEventKey) {
      this.awaitTrackUserEvent(event, 'track_user_event').then(() => {
        this.openEntriModal()
      })
    } else {
      this.openEntriModal()
    }
  }

  checkConnection(event) {
    this.awaitPost(event, 'check_connection')
  }

  validateOwnership(event) {
    this.awaitPost(event, 'validate_ownership')
  }

  async awaitTrackUserEvent(event, path) {
    let domainId = event.params.domainId
    let trackUserEventKey = event.params.trackUserEventKey

    await post(`/account/domains/${domainId}/${path}`, {
      body: { track_user_event_key: trackUserEventKey },
      contentType: 'application/json',
    })
  }

  async awaitPost(event, path) {
    let button = event.target
    let domainId = event.params.domainId
    let target = path === 'check_connection' ? 'checkConnection' : 'validateOwnership'
    let text = this[`${target}TextTarget`]
    let loader = this[`${target}LoaderTarget`]

    button.disabled = true
    text.hidden = true
    loader.hidden = false

    await post(`/account/domains/${domainId}/${path}`, { responseKind: 'turbo-stream' }).then(() => {
      loader.hidden = true
      text.hidden = false
      button.disabled = false
    })
  }

  handleOnEntriClose(event) {
    if (event.detail['success'] == true) {
      $('#new-domain-setup').hide()
      $('#redirect-toggle-box').hide()
      $('.error_during_setup').hide() // in case of Entri retry
      $('.waiting_on_confirmation').show()
    } else {
      $('.error_during_setup').show()
    }
  }

  handleEntriPurchase({ detail }) {
    if (!detail['setupType'] === 'purchase') return

    if (detail['success']) {
      window.location = `/account/workspaces/${this.workspaceIdValue}/domains/purchase_success?domain=${detail.domain}`
    }
  }

  handleDNSSetup(event) {
    if (event.detail['success'] == true) {
      this.checkConnection(event)
    } else {
      // don't refresh the DNS table on early exit
      return
    }
  }

  openEntriModal() {
    let config = this.configValue
    // entri is a cdn package included in the view
    entri.showEntri(config)
  }
}
