import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['assignments', 'toggle']

  connect() {
    if (this.hasToggleTarget) {
      this.assignmentsTarget.required = this.toggleTarget.checked
    }
  }

  handleFormInput(event) {
    this.assignmentsTarget.required = event.target.checked
  }
}
