/* global console, FormData, fetch, window, document */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['viewMode', 'editMode', 'editButton', 'form', 'submitButton', 'box']

  connect() {
    this.showViewMode()
  }

  showViewMode() {
    this.viewModeTarget.classList.remove('hidden')
    this.editModeTarget.classList.add('hidden')
    this.editButtonTarget.classList.remove('hidden')
    // Collapse the box when returning to view mode
    if (this.hasBoxTarget) {
      const collapsibleController = this.application.getControllerForElementAndIdentifier(this.boxTarget, 'collapsible')
      if (collapsibleController && !collapsibleController.isCollapsed) {
        collapsibleController.collapse()
      }
    }
  }

  showEditMode() {
    this.viewModeTarget.classList.add('hidden')
    this.editModeTarget.classList.remove('hidden')
    this.editButtonTarget.classList.add('hidden')
  }

  toggleEdit() {
    if (this.viewModeTarget.classList.contains('hidden')) {
      this.showViewMode()
    } else {
      this.showEditMode()
      // Expand the box when editing
      if (this.hasBoxTarget) {
        const collapsibleController = this.application.getControllerForElementAndIdentifier(
          this.boxTarget,
          'collapsible'
        )
        if (collapsibleController && collapsibleController.isCollapsed) {
          collapsibleController.expand()
        }
      }
    }
  }

  cancelEdit(event) {
    event.preventDefault()
    this.formTarget.reset()
    this.showViewMode()
  }

  async submitForm(event) {
    event.preventDefault()
    this.submitButtonTarget.disabled = true

    try {
      const token = document.querySelector('meta[name="csrf-token"]').content
      const formData = new FormData(this.formTarget)

      const response = await fetch(this.formTarget.action, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': token,
          Accept: 'text/html,application/xhtml+xml,application/xml',
        },
        body: formData,
        credentials: 'same-origin',
      })

      if (response.ok) {
        // Refresh the page to show updated data
        window.location.reload()
      } else {
        this.submitButtonTarget.disabled = false
      }
    } catch (error) {
      console.error('Error updating display settings:', error)
      this.submitButtonTarget.disabled = false
    }
  }
}
