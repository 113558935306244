/**
 * TableCollapseController
 *
 * Handles collapsing and expanding table rows.
 * * @example
 *  <tr data-action="click->tables--collapse#toggle">
 *    <td>Row 1 <i class="text-xs text-gray-400 fas fa-caret-down" data-tables--collapse-target="caret"></i></td>
 *  </tr>
 *  <tr data-collapsable>
 *    <td>Row 2</td>
 *  </tr>
 *  <tr data-action="click->tables--collapse#toggle">
 *    <td>Row 3 <i class="text-xs text-gray-400 fas fa-caret-down" data-tables--collapse-target="caret"></i></td>
 *  </tr>
 *  <tr data-collapsable>
 *    <td>Row 4</td>
 *  </tr>
 */

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['row', 'caret']

  /**
   * Toggles hiding/showing of rows.
   *
   * @param {Event} event - Click event on row
   * Gets triggered by clicking on a row or an element within the row with the data-action attribute.
   */
  toggle(event) {
    const clickedElement = event.currentTarget
    const clickedRow = clickedElement.closest('tr')
    let nextRow = clickedRow.nextElementSibling
    const caret = this.caretTargets.find((target) => {
      return clickedRow.contains(target)
    })

    if (caret) {
      caret.classList.toggle('rotate-90')
    }

    while (nextRow && nextRow.hasAttribute('data-collapsable')) {
      nextRow.classList.toggle('invisible')
      nextRow.classList.toggle('hidden')
      nextRow.classList.toggle('opacity-0')
      nextRow = nextRow.nextElementSibling
    }
  }
}
