/* global window */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  toggleCommandBar() {
    if (window.CommandBar !== undefined) {
      window.CommandBar.toggleHelpHub()
    }
  }
}
