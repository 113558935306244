import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'header',
    'description',
    'ctaLink',
    'secondaryCtaLink',
    'titleInput',
    'descriptionInput',
    'ctaUrlInput',
    'ctaTextInput',
    'secondaryCtaUrlInput',
    'secondaryCtaTextInput',
  ]

  static values = {
    ctaLinkOriginalText: String,
    secondaryCtaLinkOriginalText: String,
  }

  ctaLinkTargetConnected() {
    this.ctaLinkOriginalTextValue = this.ctaLinkTarget.innerText.trim()
  }

  secondaryCtaLinkTargetConnected() {
    this.secondaryCtaLinkOriginalTextValue = this.secondaryCtaLinkTarget.innerText.trim()
  }

  handleInputChange(event) {
    const { fieldTarget } = event.params

    switch (fieldTarget) {
      case 'title':
        this.updateHeader()
        break
      case 'description':
        this.updateDescription()
        break
      case 'cta_url':
        this.updateLinkUrl()
        break
      case 'cta_text':
        this.updateLinkText()
        break
      case 'secondary_cta_url':
        this.updateSecondaryLinkUrl()
        break
      case 'secondary_cta_text':
        this.updateSecondaryLinkText()
        break
      default:
        break
    }
  }

  updateHeader() {
    this.headerTarget.innerText = this.titleInputTarget.value
  }

  updateDescription() {
    this.descriptionTarget.innerText = this.descriptionInputTarget.value
  }

  updateLinkUrl() {
    this.ctaLinkTarget.href = this.ctaUrlInputTarget.value
  }

  updateLinkText() {
    this.ctaLinkTarget.innerText = this.ctaTextInputTarget.value || this.ctaLinkOriginalTextValue
  }

  updateSecondaryLinkUrl() {
    this.secondaryCtaLinkTarget.href = this.secondaryCtaUrlInputTarget.value
  }

  updateSecondaryLinkText() {
    this.secondaryCtaLinkTarget.innerText =
      this.secondaryCtaTextInputTarget.value || this.secondaryCtaLinkOriginalTextValue
  }
}
