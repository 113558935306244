/* global setTimeout, window */

import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['loader', 'form']
  static values = {
    redirectPath: String,
    autoCloseModal: Boolean,
    autoCloseTimeout: { type: Number, default: 30000 },
    notificationPath: String,
    customEvent: String,
    loaderHiddenCss: { type: String, default: 'hidden' },
    loaderVisibleCss: { type: String, default: '' },
  }

  get hasRedirectPath() {
    return this.hasRedirectPathValue && this.redirectPathValue.trim().length > 0
  }

  connect() {
    if (this.hasAutoCloseModalValue) {
      setTimeout(() => {
        this.dispatch(this.customEventValue)
        if (this.hasNotificationPathValue) {
          get(this.notificationPathValue, { responseKind: 'turbo-stream' })
        }
      }, this.autoCloseTimeoutValue)
    }
  }

  redirectPathValueChanged() {
    if (this.hasRedirectPath) {
      window.location.replace(this.redirectPathValue)
    }
  }

  showLoader() {
    if (this.hasLoaderTarget) {
      const hiddenCss = this.loaderHiddenCssValue.split(' ')
      const visibleCss = this.loaderVisibleCssValue.split(' ')
      this.loaderTarget.classList.remove(...hiddenCss)
      this.loaderTarget.classList.add(...visibleCss)
    }
  }
}
