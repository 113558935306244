import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    src: String,
  }

  loadSrc({ params: { forceUpdateSrc = false } }) {
    if (this.element.src && !forceUpdateSrc) {
      this.reload()
    } else {
      this.element.src = this.srcValue
    }
  }

  reload() {
    this.element.reload()
  }
}
